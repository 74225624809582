<template>
  <v-card-text class="text-h5 pa-0">
    <FormSkeleton
      v-if="
        (supplierId && Object.keys(supplierById).length <= 0) ||
        (copiedSupplier && copyFormLoading)
      "
    />
    <v-form
      v-else
      ref="form"
      v-model="valid"
      lazy-validation
      :disabled="accessRight.length < 2 && accessRight.includes('show')"
    >
      <Alert :alert.sync="alert" :message="message" />
      <v-row>
        <v-col cols="12">
          <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="e6 > 1"
              @click.native="accordionStepper(e6)"
              editable
              step="1"
              :rules="[
                (value) => !!model.name_1,
                (value) => !!model.name_1,
                (value) => !/^\s*$/.test(model.name_1),
                (value) =>
                  !model.name_1 || (model.name_1 && model.name_1.length <= 200),
                (value) => !model.email || /.+@.+\..+/.test(model.email),
                (value) =>
                  !model.email || (model.email && model.email.length <= 64),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.postalCode1'),
                    $t('common.less'),
                    10,
                    model.postal_code_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.pobox1'),
                    $t('common.less'),
                    10,
                    model.po_box_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.address1'),
                    $t('common.less'),
                    250,
                    model.address_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.town1'),
                    $t('common.less'),
                    100,
                    model.town_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.state'),
                    $t('common.less'),
                    200,
                    model.state
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.country'),
                    $t('common.less'),
                    200,
                    model.country
                  ),
              ]"
            >
              <v-row :dense="true">
                <v-col cols="6" :dense="true">
                  <h6 class="primary--text mt-2 font-weight-regular">
                    {{ $t("supplier.basicDetails") }}
                  </h6>
                </v-col>
                <v-col cols="6" class="text-end" :dense="true">
                  <v-menu
                    bottom
                    v-if="supplierId"
                    :close-on-click="true"
                    :offset-y="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="
                          remarksList && remarksList.length > 0
                            ? 'success'
                            : 'primary'
                        "
                        class="ma-2"
                        outlined
                        small
                        @click="remarksDialog = true"
                        :disabled="
                          accessRight.length < 2 && accessRight.includes('show')
                        "
                      >
                        {{ $t("remarks.remarks") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        class="ma-2"
                        small
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          !valid ||
                          (!!model.is_locked && model.user_id !== user.id)
                        "
                      >
                        <v-icon left> mdi-menu-down </v-icon>
                        {{ $t("quotation.options") }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="accessRight.includes('create')"
                        dense
                        class="primary--text"
                        @click.stop="copySupplier"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="primary--text">
                              mdi-content-copy
                            </v-icon>
                            {{ $t("quotation.copy") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        dense
                        class="primary--text"
                        @click.stop="actDialog = true"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="primary--text">
                              mdi-pulse
                            </v-icon>
                            {{ $t("quotation.activityLog") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        dense
                        class="primary--text"
                        @click="attachedDocumentsDialog = true"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="primary--text">
                              mdi-file-document
                            </v-icon>
                            {{ $t("employee.attachedDocuments") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        dense
                        v-if="accessRight.includes('delete')"
                        class="error--text"
                        @click="
                          (confirmationDialog = true),
                            (delete_item = supplierId)
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon small class="error--text">
                              mdi-delete-sweep-outline
                            </v-icon>
                            {{ $t("common.delete") }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    small
                    color="primary"
                    :loading="loading"
                    :disabled="
                      !valid ||
                      (!!model.is_locked && model.user_id !== user.id) ||
                      (accessRight.length < 2 && accessRight.includes('show'))
                    "
                    @click="saveRole"
                  >
                    {{ $t("supplier.save") }}
                  </v-btn>
                  <v-btn
                    v-if="$route.params.supplier_id"
                    small
                    class="text-capitalize ms-2"
                    outlined
                    color="primary"
                    :loadingSaveAndContinue="loadingSaveAndContinue"
                    :disabled="
                      !valid ||
                      (!!model.is_locked && model.user_id !== user.id) ||
                      (accessRight.length < 2 && accessRight.includes('show'))
                    "
                    @click="saveAndContinue"
                  >
                    {{ $t("supplier.saveAndContinue") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-step>

            <v-stepper-content
              step="1"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model.trim="model.name_1"
                    :label="$t('supplier.supplierName')"
                    :rules="supplierValid"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.email"
                    :label="$t('supplier.field.email')"
                    :rules="emailValid"
                    required
                  />
                  <v-select
                    hide-details="auto"
                    v-model="selectedGroup"
                    :items="groupValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('supplier.field.group')"
                  />
                  <v-select
                    hide-details="auto"
                    v-model="selectedStatus"
                    :items="statusValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('supplier.field.status')"
                  />
                  <v-select
                    hide-details="auto"
                    v-model="selectedPaymentTerms"
                    :items="paymentTermsValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('supplier.field.termsOfPayment')"
                  />
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="model.supplier_opening_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        v-model="formattedStartDate"
                        :label="$t('supplier.field.supplierOpeningDate')"
                        v-bind="attrs"
                        :rules="
                          profileById.date_format ? dateValid : dateValid2
                        "
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="model.supplier_opening_date"
                      @change="
                        $refs.startDateMenu.save(model.supplier_opening_date)
                      "
                    >
                      <v-btn
                        small
                        class="primary"
                        @click="$refs.startDateMenu.save(todayDate())"
                        >{{ $t("route.today") }}</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="model.supplier_end_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        v-model="formattedEndDate"
                        :label="$t('supplier.field.supplierEndDate')"
                        v-bind="attrs"
                        :rules="
                          profileById.date_format ? dateValid : dateValid2
                        "
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="
                        model.supplier_opening_date
                          ? model.supplier_opening_date
                          : ''
                      "
                      v-model="model.supplier_end_date"
                      @change="$refs.endDateMenu.save(model.supplier_end_date)"
                    >
                      <v-btn
                        small
                        class="primary"
                        @click="$refs.endDateMenu.save(todayDate())"
                        >{{ $t("route.today") }}</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    hide-details="auto"
                    v-model="selectedRegion"
                    :items="regionValue"
                    item-text="description"
                    item-value="id"
                    return-object
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="$t('supplier.field.area')"
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.address_1"
                    :label="$t('supplier.field.address1')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.address1'),
                        $t('common.less'),
                        250,
                        model.address_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.postal_code_1"
                    :label="$t('supplier.field.postalCode1')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.postalCode1'),
                        $t('common.less'),
                        10,
                        model.postal_code_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.po_box_1"
                    :label="$t('supplier.field.pobox1')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.pobox1'),
                        $t('common.less'),
                        10,
                        model.po_box_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.town_1"
                    :label="$t('supplier.field.town1')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.town1'),
                        $t('common.less'),
                        100,
                        model.town_1
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.state"
                    :label="$t('supplier.field.state')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.state'),
                        $t('common.less'),
                        200,
                        model.state
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.country"
                    :label="$t('supplier.field.country')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.country'),
                        $t('common.less'),
                        200,
                        model.country
                      ),
                    ]"
                    required
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    disabled
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 2"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="e6 > 2"
              @click.native="accordionStepper(e6)"
              editable
              :rules="[
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.postalCode2'),
                    $t('common.less'),
                    10,
                    model.postal_code_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.pobox2'),
                    $t('common.less'),
                    10,
                    model.po_box_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.site'),
                    $t('common.less'),
                    100,
                    model.site
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.authorizedDealerNo'),
                    $t('common.less'),
                    30,
                    model.authorized_dealer_no
                  ),
                (value) =>
                  !model.book_kiping_no ||
                  /^[0-9]*$/.test(model.book_kiping_no),
                (value) =>
                  !model.book_kiping_no || model.book_kiping_no.length <= 15,
                (value) =>
                  !profileById.customer_bookkeeping_number ||
                  !!model.book_kiping_no,
                (value) => !model.name_2 || !/^\s*$/.test(model.name_2),
                (value) =>
                  !model.name_2 || (model.name_2 && model.name_2.length <= 200),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.address2'),
                    $t('common.less'),
                    200,
                    model.address_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.town2'),
                    $t('common.less'),
                    200,
                    model.town_2
                  ),
              ]"
              step="2"
            >
              <h6 class="primary--text font-weight-regular">
                {{ $t("supplier.otherDetails") }}
              </h6>
            </v-stepper-step>

            <v-stepper-content
              step="2"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    v-model.trim="model.name_2"
                    :label="$t('supplier.supplierName2')"
                    :rules="supplier2Valid"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.address_2"
                    :label="$t('supplier.field.address2')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.address2'),
                        $t('common.less'),
                        200,
                        model.address_2
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.postal_code_2"
                    :label="$t('supplier.field.postalCode2')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.postalCode2'),
                        $t('common.less'),
                        10,
                        model.postal_code_2
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.po_box_2"
                    :label="$t('supplier.field.pobox2')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.pobox2'),
                        $t('common.less'),
                        10,
                        model.po_box_2
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.fixed_discount"
                    :label="$t('customer.field.fixedDiscount')"
                    :rules="fixedDiscountValid"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="model.town_2"
                    :label="$t('supplier.field.town2')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.town2'),
                        $t('common.less'),
                        200,
                        model.town_2
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model="model.site"
                    :label="$t('supplier.field.site')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.site'),
                        $t('common.less'),
                        100,
                        model.site
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    hide-details="auto"
                    v-model.trim="model.authorized_dealer_no"
                    :label="$t('supplier.field.authorizedDealerNo')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.authorizedDealerNo'),
                        $t('common.less'),
                        30,
                        model.authorized_dealer_no
                      ),
                    ]"
                    required
                  />
                  <v-text-field
                    class="asterisk"
                    hide-details="auto"
                    v-model.trim="model.book_kiping_no"
                    :label="$t('supplier.field.bookKipingNo')"
                    :rules="bookkipingNoValid"
                    required
                  />
                  <v-checkbox
                    hide-details="auto"
                    v-model="model.vat_charge"
                    :label="$t('customer.field.vatCharge')"
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 1"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 3"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="e6 > 3"
              @click.native="accordionStepper(e6)"
              editable
              step="3"
              :rules="[
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.warningNote'),
                    $t('common.less'),
                    255,
                    model.warning_note
                  ),
              ]"
            >
              <h6 class="primary--text font-weight-regular">
                {{ $t("supplier.notes") }}
              </h6>
            </v-stepper-step>

            <v-stepper-content
              step="3"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    hide-details="auto"
                    v-model="model.warning_note"
                    :label="$t('supplier.field.warningNote')"
                    :rules="[
                      lessThanValidation(
                        $t('supplier.field.warningNote'),
                        $t('common.less'),
                        255,
                        model.warning_note
                      ),
                    ]"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-textarea
                    class="mt-4"
                    hide-details="auto"
                    dense
                    v-model="model.general_remarks"
                    :label="$t('supplier.field.generalRemarks')"
                  />
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 2"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 4"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-step
              class="py-3 px-4 text-h6"
              :complete="e6 > 4"
              @click.native="accordionStepper(e6)"
              editable
              step="4"
              :rules="[
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.extension0'),
                    $t('common.less'),
                    6,
                    model.extension_1
                  ),
                (value) => !model.phone_1 || /^[0-9]*$/.test(model.phone_1),
                (value) =>
                  !model.phone_1 ||
                  (model.phone_1 &&
                    model.phone_1.length >= 7 &&
                    model.phone_1.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_1
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.extension0'),
                    $t('common.less'),
                    6,
                    model.extension_2
                  ),
                (value) => !model.phone_2 || /^[0-9]*$/.test(model.phone_2),
                (value) =>
                  !model.phone_2 ||
                  (model.phone_2 &&
                    model.phone_2.length >= 7 &&
                    model.phone_2.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_2
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.extension0'),
                    $t('common.less'),
                    6,
                    model.extension_3
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_3
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_3
                  ),
                (value) => !model.phone_3 || /^[0-9]*$/.test(model.phone_3),
                (value) =>
                  !model.phone_3 ||
                  (model.phone_3 &&
                    model.phone_3.length >= 7 &&
                    model.phone_3.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_4
                  ),
                (value) => !model.phone_4 || /^[0-9]*$/.test(model.cellular_1),
                (value) =>
                  !model.cellular_1 ||
                  (model.cellular_1 &&
                    model.cellular_1.length >= 7 &&
                    model.cellular_1.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_4
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_5
                  ),
                (value) => !model.phone_4 || /^[0-9]*$/.test(model.cellular_2),
                (value) =>
                  !model.cellular_2 ||
                  (model.cellular_2 &&
                    model.cellular_2.length >= 7 &&
                    model.cellular_2.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_5
                  ),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.dialingArea0'),
                    $t('common.less'),
                    5,
                    model.area_prefix_6
                  ),
                (value) => !model.fax || /^[0-9]*$/.test(model.fax),
                (value) =>
                  !model.fax ||
                  (model.fax &&
                    model.fax.length >= 7 &&
                    model.fax.length <= 17),
                (value) =>
                  lessThanValidation(
                    $t('supplier.field.countryPrefix0'),
                    $t('common.less'),
                    5,
                    model.country_prefix_6
                  ),
              ]"
            >
              <h6 class="primary--text font-weight-regular">
                {{ $t("supplier.displayText") }}
              </h6>
            </v-stepper-step>

            <v-stepper-content
              step="4"
              class="ml-7 mb-auto pb-0 mr-3 py-2 px-4"
            >
              <v-row>
                <v-col cols="12">
                  <v-simple-table dense height="340px" fixed-header>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th style="width: 120px">
                            {{ $t("supplier.communication") }}
                          </th>
                          <th>
                            {{ $t("supplier.country") }}
                          </th>
                          <th>
                            {{ $t("supplier.areaCode") }}
                          </th>
                          <th>
                            {{ $t("supplier.no") }}
                          </th>
                          <th>
                            {{ $t("supplier.extension") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ $t("supplier.tel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_1"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_1
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_1"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_1
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.phone_1"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.extension_1"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.extension0'),
                                  $t('common.less'),
                                  6,
                                  model.extension_1
                                ),
                              ]"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("supplier.tel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_2"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_2
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_2"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_2
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.phone_2"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.extension_2"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.extension0'),
                                  $t('common.less'),
                                  6,
                                  model.extension_2
                                ),
                              ]"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("supplier.tel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_3"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_3
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_3"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_3
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.phone_3"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.extension_3"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.extension0'),
                                  $t('common.less'),
                                  6,
                                  model.extension_3
                                ),
                              ]"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("supplier.cel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_4"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_4
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_4"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_4
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.cellular_1"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                          <td rowspan="3" class="text-top"></td>
                        </tr>
                        <tr>
                          <td>{{ $t("supplier.cel") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_5"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_5
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_5"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_5
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.cellular_2"
                              :rules="phoneValid"
                              required
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("supplier.fax") }}</td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.country_prefix_6"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.countryPrefix0'),
                                  $t('common.less'),
                                  5,
                                  model.country_prefix_6
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.area_prefix_6"
                              :rules="[
                                lessThanValidation(
                                  $t('supplier.field.dialingArea0'),
                                  $t('common.less'),
                                  5,
                                  model.area_prefix_6
                                ),
                              ]"
                              required
                            />
                          </td>
                          <td>
                            <v-text-field
                              hide-details="auto"
                              v-model="model.fax"
                              :rules="faxValid"
                              required
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row class="ma-0 my-2">
                <v-col cols="12" class="text-center pa-0">
                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                    @click="e6 = 3"
                  >
                    <v-icon>mdi-chevron-double-up</v-icon>
                  </v-btn>
                  <v-btn
                    disabled
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="primary"
                  >
                    <v-icon>mdi-chevron-double-down</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper>
        </v-col>
        <ActivityLogTableDialog
          :actDialog.sync="actDialog"
          :docId.sync="supplierId"
          docType="Supplier"
        />
        <RemarksDialog
          :dialog.sync="remarksDialog"
          :docId="supplierId"
          :docType="39"
        />
        <AttachedDocumentsDialog
          :docId="supplierId"
          docType="Supplier"
          :dialog.sync="attachedDocumentsDialog"
        />
        <confirmation-dialog
          :dialog.sync="confirmationDialog"
          :delete_item.sync="delete_item"
          @deleteItem="deleteItem"
        />
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import { mapGetters } from "vuex";
import { getAuxiliaryZtableValueByName, changedFormData } from "@/utils";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import ActivityLogTableDialog from "@/components/activityLog/Dialogs/ActivityLogTableDialog.vue";
import Alert from "@/components/Alert";
import { formatDateDDMMYYYY, todayDate } from "../../../../utils";
import RemarksDialog from "@/components/RemarksDialog";
import AttachedDocumentsDialog from "@/components/AttachedDocumentsDialog";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  props: ["supplierName", "inputData", "loading", "loadingSaveAndContinue"],
  name: "SupplierStep",
  components: {
    FormSkeleton,
    Alert,
    ActivityLogTableDialog,
    RemarksDialog,
    AttachedDocumentsDialog,
    ConfirmationDialog,
  },
  mixins: [validationMixin],
  data() {
    return {
      message: "",
      alert: false,
      e6: 1,
      valid: false,
      model: {
        vat_charge: true,
      },
      formattedStartDate: "",
      formattedEndDate: "",
      originModel: {},
      activePicker: null,
      startDateMenu: false,
      previousStep: 1,
      endDateMenu: false,
      groupValue: [],
      regionValue: [],
      statusValue: [],
      paymentTermsValue: [],
      selectedGroup: null,
      selectedRegion: null,
      selectedStatus: null,
      selectedPaymentTerms: null,
      selectedEmployee: null,
      actDialog: false,
      copyFormLoading: true,
      attachedDocumentsDialog: false,
      remarksDialog: false,
      confirmationDialog: false,
      delete_item: null,
    };
  },
  async mounted() {
    if (this.$route.params.copiedModel) {
      this.model = this.$route.params.copiedModel;
      this.selectedGroup = this.model.group;
      this.selectedRegion = this.model.region;
      this.selectedStatus = this.model.status;
      this.selectedPaymentTerms = this.model.paymentTerms;
      this.selectedEmployee = this.model.employee;
      this.copyFormLoading = false;
    } else if (this.$route.params.supplier_id) {
      await this.$store.dispatch(
        "supplier/GetSupplierById",
        this.$route.params.supplier_id
      );
      if (!this.model.is_locked && this.$route.params.supplier_id) {
        await this.$store.dispatch("PostDocumentOpenAction", {
          table: "Supplier",
          id: this.$route.params.supplier_id,
          is_locked: true,
        });
      }
      this.selectedGroup = this.model.group;
      this.selectedRegion = this.model.region;
      this.selectedStatus = this.model.status;
      this.selectedPaymentTerms = this.model.paymentTerms;
      this.selectedEmployee = this.model.employee;
    }
    if (this.supplierId) {
      this.$store.dispatch("remarks/GetRemarks", {
        where: "document_type|" + 39,
        where_and: "document_id|" + this.supplierId,
      });
    }
    this.$store.dispatch("profile/GetCompanyById");
    let groupTableNameValue = await getAuxiliaryZtableValueByName(4);
    if (Object.keys(groupTableNameValue).length > 0) {
      this.groupValue = groupTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    let regionTableNameValue = await getAuxiliaryZtableValueByName(6);
    if (Object.keys(regionTableNameValue).length > 0) {
      this.regionValue = regionTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    let statusTableNameValue = await getAuxiliaryZtableValueByName(5);
    if (Object.keys(statusTableNameValue).length > 0) {
      this.statusValue = statusTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
    let paymentTermsTableNameValue = await getAuxiliaryZtableValueByName(7);
    if (Object.keys(paymentTermsTableNameValue).length > 0) {
      this.paymentTermsValue = paymentTermsTableNameValue.ztables.sort((a, b) =>
        a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
          ? 1
          : -1
      );
    }
  },
  computed: {
    buttonColor() {
      return this.remarksData;
    },
    supplierValid() {
      return [
        (v) => !!v || this.$t("formRules.supplierRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.supplierRequired"),
        (v) =>
          !v ||
          (v && v.length <= 200) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("supplier.supplierName"),
            type: this.$t("common.less"),
            size: 200,
          }),
      ];
    },
    supplier2Valid() {
      return [
        (v) => !v || !/^\s*$/.test(v) || this.$t("formRules.spaceValidValue"),
        (v) =>
          !v ||
          (v && v.length <= 200) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("supplier.supplierName2"),
            type: this.$t("common.less"),
            size: 200,
          }),
      ];
    },
    emailValid() {
      return [
        (v) =>
          !v || /.+@.+\..+/.test(v) || this.$t("formRules.emailValidMessage"),
        (v) =>
          !v ||
          (v && v.length <= 64) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("supplier.field.email"),
            type: this.$t("common.less"),
            size: 64,
          }),
      ];
    },
    bookkipingNoValid() {
      return [
        (v) =>
          !this.profileById.customer_bookkeeping_number ||
          !!v ||
          this.$t("formRules.bookkipingNoRequired"),
        (v) =>
          !v || /^[0-9]*$/.test(v) || this.$t("formRules.bookkipingNoInteger"),
        (v) =>
          !v ||
          (v && v.length <= 15) ||
          this.$t("formRules.bookkipingNoValidMessage"),
      ];
    },
    fixedDiscountValid() {
      return [
        (v) =>
          !v || /^[0-9]*$/.test(v) || this.$t("formRules.fixedDiscountInteger"),
        (v) =>
          !v ||
          (v && v.length <= 2) ||
          this.$t("formRules.fixedDiscountValidMessage"),
      ];
    },
    phoneValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.phoneNoInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.phoneNoValidMessage"),
      ];
    },
    faxValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.faxInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.faxValidMessage"),
      ];
    },
    supplierId() {
      return this.$route.params.supplier_id;
    },
    copiedSupplier() {
      return this.$route.params.copiedModel;
    },
    ...mapGetters({
      supplierById: "supplier/supplierById",
      remarksList: "remarks/remarksList",
      accessRight: "accessRight",
      profileById: "profile/profileById",
      user: "user",
    }),
  },
  watch: {
    "model.supplier_opening_date"(val) {
      this.formattedStartDate = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
    },
    "model.supplier_end_date"(val) {
      this.formattedEndDate = this.profileById.date_format
        ? val
        : formatDateDDMMYYYY(val);
    },
    profileById(val) {
      this.formattedEndDate = val.date_format
        ? formatDateDDMMYYYY(this.model.supplier_opening_date)
        : this.model.supplier_opening_date;
      this.formattedStartDate = val.date_format
        ? formatDateDDMMYYYY(this.model.supplier_end_date)
        : this.model.supplier_end_date;
    },
    e6() {
      if (this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    supplierById() {
      if (Object.keys(this.supplierById).length > 0) {
        this.model = Object.assign({}, this.model, this.supplierById);
        if (this.model.supplier_opening_date) {
          this.model.supplier_opening_date = new Date(
            this.model.supplier_opening_date
          )
            .toISOString()
            .substring(0, 10);
        }
        if (this.model.supplier_end_date) {
          this.model.supplier_end_date = new Date(this.model.supplier_end_date)
            .toISOString()
            .substring(0, 10);
        }
        this.$emit("update:supplierName", this.model.name_1);
        this.originModel = Object.assign({}, this.originModel, this.model);
        this.$emit("update:inputData", this.originModel);
      }
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    todayDate: todayDate,
    copySupplier() {
      let copyModel = this.originModel;
      this.$router.push({
        name: "addSupplier",
        params: {
          copiedModel: copyModel,
        },
      });
    },
    accordionStepper(current) {
      if (this.previousStep == current && this.previousStep != 5) {
        this.e6 = 5;
        this.previousStep = 5;
      } else {
        this.previousStep = current;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && v.length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    saveRole() {
      if (this.$refs.form.validate()) {
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.group_id = this.selectedGroup.id;
        }
        if (
          this.selectedRegion &&
          Object.keys(this.selectedRegion).length > 0
        ) {
          this.model.region_id = this.selectedRegion.id;
        }
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status_id = this.selectedStatus.id;
        }
        if (
          this.selectedPaymentTerms &&
          Object.keys(this.selectedPaymentTerms).length > 0
        ) {
          this.model.payment_terms_id = this.selectedPaymentTerms.id;
        }
        if (
          this.selectedEmployee &&
          Object.keys(this.selectedEmployee).length > 0
        ) {
          this.model.employee_id = this.selectedEmployee.id;
        }
        // save only update model value using changedFormData from utils
        let saveData = changedFormData(this.model, this.originModel);
        this.$emit("update:supplierName", this.model.name_1);
        this.$emit("update:inputData", saveData);
        this.$emit("saveRole");
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    saveAndContinue() {
      if (this.$refs.form.validate()) {
        if (this.selectedGroup && Object.keys(this.selectedGroup).length > 0) {
          this.model.group_id = this.selectedGroup.id;
        }
        if (
          this.selectedRegion &&
          Object.keys(this.selectedRegion).length > 0
        ) {
          this.model.region_id = this.selectedRegion.id;
        }
        if (
          this.selectedStatus &&
          Object.keys(this.selectedStatus).length > 0
        ) {
          this.model.status_id = this.selectedStatus.id;
        }
        if (
          this.selectedPaymentTerms &&
          Object.keys(this.selectedPaymentTerms).length > 0
        ) {
          this.model.payment_terms_id = this.selectedPaymentTerms.id;
        }
        if (
          this.selectedEmployee &&
          Object.keys(this.selectedEmployee).length > 0
        ) {
          this.model.employee_id = this.selectedEmployee.id;
        }
        // save only update model value using changedFormData from utils
        let saveData = changedFormData(this.model, this.originModel);
        this.$emit("update:supplierName", this.model.name_1);
        this.$emit("update:inputData", saveData);
        this.$emit("saveRole", true);
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    deleteItem() {
      this.$store.dispatch("supplier/DeleteSupplierItemById", this.supplierId);
      this.$router.push("/supplier");
    },
  },
  beforeDestroy() {
    this.$refs.form.reset();
    (this.model = {}), this.$store.commit("supplier/SET_SUPPLIER_BY_ID", {});
    this.$store.commit("contact/SET_CONTACT", []);
  },
};
</script>
<style scoped>
.v-stepper__header {
  height: 48px !important;
}
</style>
