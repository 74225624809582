<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="my-1">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('contact.contactsList') }}</span>
        <v-btn
          v-if="accessRight.includes('create')"
          class="float-end"
          outlined
          small
          color="primary"
          @click="dialog = true"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('contact.contact') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="supplierId && Object.keys(supplierById).length <= 0"/>
          <v-card-text class="text-h5 pa-0" v-else-if="contactList.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="contactList.data"
              :items-per-page="limit"
              :page.sync="page"
              item-class="py-4"
              :server-items-length="contactList.meta.total"
              style="word-break: break-word"
              :options.sync="pagination"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :search="search"
              @dblclick:row="redirectOnEditPage"
            >
              <template
                v-slot:top
              >
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="4" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`item.actions`]="props">
                <v-menu
                  v-if="(accessRight.includes('edit')) || (accessRight.includes('delete'))"
                  offset-x
                  left
                  content-class="elevation-2"
                  flat
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-if="accessRight.includes('edit')" dense class="primary--text" @click="contact_id = props.item.id, dialog = true">
                      <v-list-item-content>
                        <v-list-item-title>{{$t('common.edit')}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="primary--text">mdi-circle-edit-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item v-if="accessRight.includes('delete')" dense @click="confirmationDialog = true, delete_item = props.item">
                      <v-list-item-content class="error--text">
                        <v-list-item-title>{{$t('common.delete')}}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon small class="error--text">mdi-delete-sweep-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{contactList.meta.total}}</span>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        :length="contactList.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ContactDialog :dialog.sync="dialog" :contact_id.sync="contact_id"/>
    <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ContactDialog from './ContactDialog.vue'
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import ConfirmationDialog from '@/components/ConfirmationDialog';
export default {
  props:['supplierId'],
  name: "ContactStep",
  components: {
    ContactDialog,
    ListSkeleton,
    ConfirmationDialog
  },
  data() {
    return {
      page: 1,
      limit: 10,
      dialog: false,
      confirmationDialog: false,
      delete_item: null,
      contact_id: '',
      search: '',
      pagination: {},
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.search
      };
    },
    ...mapGetters({
      contactList: 'contact/contactList',
      supplierById: 'supplier/supplierById',
      accessRight: 'accessRight'
    }),
    headers() {
      return [
        {
          text: this.$t('roles.id'),
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: this.$t('contact.contactName'), value: "name", width: "480px" },
        { text: this.$t('roles.action'), value: "actions", align: "center", sortable: false },
      ]
    }
  },
  watch: {
    params: {
      handler() {
        this.paginate();
      },
      deep: true
    }
  },
  methods: {
    async paginate() {
      const { sortBy, sortDesc, page, itemsPerPage, query } = this.params;
      await this.$store.dispatch('contact/GetContact', {page: page, limit: itemsPerPage, where_like: query ? 'id,name|'+query : 'id,name|', order_by: sortBy[0] ? sortBy[0]+'|'+(sortDesc[0] ? 'desc' : 'asc') : 'name|'+(sortDesc[0] ? 'desc' : 'asc'), where_and: 'record_type_number,record_type|'+this.$route.params.supplier_id+',suppliers'});
    },
    redirectOnEditPage(event, {item}) {
      this.contact_id = item.id;
      this.dialog = true;
    },
    async deleteItem(item) {
      this.search = '';
      this.page = 1;
      this.limit = 10;
      await this.$store.dispatch('contact/DeleteContactItemById', item);
      await this.$store.dispatch('contact/GetContact', {page: 1, limit: 10, where_and: 'record_type_number,record_type|'+this.$route.params.supplier_id+',suppliers', order_by: 'name|asc'}, {root: true});
    }
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>