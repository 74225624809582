<template>
  <div>
    <v-dialog
      class="pa-0"
      v-model="dialog"
      @click:outside="$emit('update:dialog', false)"
      max-width="50%"
    >
      <v-card>
        <v-card-title class="pa-2">
          <h5
            style="max-width: 200px"
            :class="
              contactId
                ? 'text-truncate font-italic primary--text px-1 font-weight-bold'
                : 'primary--text text--darken-1'
            "
            class="font-weight-regular"
          >
            {{
              contactId
                ? originModel.name && `${contactId}. ${originModel.name}`
                : $t("contact.addContact")
            }}
          </h5>
          <v-spacer />
          <v-btn icon @click="$emit('update:dialog', false)">
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-h5 px-4">
          <FormSkeleton
            v-if="contactId && Object.keys(contactById).length <= 0"
          />
          <v-form v-else ref="form" v-model="valid" lazy-validation :disabled="(accessRight.length < 2 && accessRight.includes('show'))">
            <Alert :alert.sync="alert" :message="message" />
            <v-row>
              <v-col cols="12">
                <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                  <v-stepper-step
                    class="py-3 px-2 text-h6"
                    :complete="e6 > 1"
                    editable
                    @click.native="accordionStepper(e6)"
                    step="1"
                    :rules="[
                      (value) => !!model.name,
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.jobTitle'),
                          $t('common.less'),
                          50,
                          model.name
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.contactName'),
                          $t('common.less'),
                          50,
                          model.job_title
                        ),
                    ]"
                  >
                    <v-row dense>
                      <v-col cols="6" dense>
                        <h6 class="primary--text mt-2 font-weight-regular">
                          {{ $t("contact.basicDetails") }}
                        </h6>
                      </v-col>
                      <v-col cols="6" class="text-end" dense>
                        <v-btn
                          color="primary"
                          small
                          :disabled="!valid || (accessRight.length < 2 && accessRight.includes('show'))"
                          :loading="loading"
                          @click="saveContact"
                        >
                          {{ $t("contact.save") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-step>

                  <v-stepper-content
                    step="1"
                    class="ml-5 mb-auto pb-0 mr-3 py-2 px-2"
                  >
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          class="asterisk"
                          hide-details="auto"
                          v-model.trim="model.name"
                          :label="$t('contact.contactName')"
                          :rules="contactValid"
                          required
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          hide-details="auto"
                          v-model="model.job_title"
                          :label="$t('contact.field.jobTitle')"
                          :rules="[
                            lessThanValidation(
                              $t('contact.field.jobTitle'),
                              $t('common.less'),
                              50,
                              model.job_title
                            ),
                          ]"
                          required
                        />
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 my-2">
                      <v-col cols="12" class="text-center pa-0">
                        <v-btn
                          disabled
                          class="ma-2"
                          outlined
                          fab
                          x-small
                          color="primary"
                        >
                          <v-icon>mdi-chevron-double-up</v-icon>
                        </v-btn>
                        <v-btn
                          class="ma-2"
                          outlined
                          fab
                          x-small
                          color="primary"
                          @click="e6 = 2"
                        >
                          <v-icon>mdi-chevron-double-down</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-step
                    class="py-3 px-2 text-h6"
                    :complete="e6 > 2"
                    editable
                    @click.native="accordionStepper(e6)"
                    :rules="[
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.remarks'),
                          $t('common.less'),
                          250,
                          model.remarks
                        ),
                    ]"
                    step="2"
                  >
                    <h6 class="primary--text font-weight-regular">
                      {{ $t("contact.otherDetails") }}
                    </h6>
                  </v-stepper-step>

                  <v-stepper-content
                    step="2"
                    class="ml-5 mb-auto pb-0 mr-3 py-2 px-2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          hide-details="auto"
                          v-model="model.remarks"
                          :label="$t('contact.field.remarks')"
                          :rules="[
                            lessThanValidation(
                              $t('contact.field.remarks'),
                              $t('common.less'),
                              250,
                              model.remarks
                            ),
                          ]"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 my-2">
                      <v-col cols="12" class="text-center pa-0">
                        <v-btn
                          class="ma-2"
                          outlined
                          fab
                          x-small
                          color="primary"
                          @click="e6 = 1"
                        >
                          <v-icon>mdi-chevron-double-up</v-icon>
                        </v-btn>
                        <v-btn
                          class="ma-2"
                          outlined
                          fab
                          x-small
                          color="primary"
                          @click="e6 = 3"
                        >
                          <v-icon>mdi-chevron-double-down</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                  <v-stepper-step
                    class="py-3 px-2 text-h6"
                    :complete="e6 > 3"
                    editable
                    @click.native="accordionStepper(e6)"
                    step="3"
                    :rules="[
                      (value) => !model.email || /.+@.+\..+/.test(model.email),
                      (value) =>
                        !model.email ||
                        (model.email && model.email.length <= 64),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.site'),
                          $t('common.less'),
                          100,
                          model.site
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.extension0'),
                          $t('common.less'),
                          6,
                          model.extension_1
                        ),
                      (value) =>
                        !model.phone_1 ||
                        /^[0-9]*$/.test(model.phone_1),
                      (value) =>
                        !model.phone_1 ||
                        (model.phone_1 &&
                          model.phone_1.length >= 7 &&
                          model.phone_1.length <= 17),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.dialingArea0'),
                          $t('common.less'),
                          5,
                          model.area_prefix_1
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.countryPrefix0'),
                          $t('common.less'),
                          5,
                          model.country_prefix_1
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.extension0'),
                          $t('common.less'),
                          6,
                          model.extension_2
                        ),
                      (value) =>
                        !model.phone_2 ||
                        /^[0-9]*$/.test(model.phone_2),
                      (value) =>
                        !model.phone_2 ||
                        (model.phone_2 &&
                          model.phone_2.length >= 7 &&
                          model.phone_2.length <= 17),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.dialingArea0'),
                          $t('common.less'),
                          5,
                          model.area_prefix_2
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.countryPrefix0'),
                          $t('common.less'),
                          5,
                          model.country_prefix_2
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.extension0'),
                          $t('common.less'),
                          6,
                          model.extension_3
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.dialingArea0'),
                          $t('common.less'),
                          5,
                          model.area_prefix_3
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.countryPrefix0'),
                          $t('common.less'),
                          5,
                          model.country_prefix_3
                        ),
                      (value) =>
                        !model.phone_3 ||
                        /^[0-9]*$/.test(model.phone_3),
                      (value) =>
                        !model.phone_3 ||
                        (model.phone_3 &&
                          model.phone_3.length >= 7 &&
                          model.phone_3.length <= 17),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.dialingArea0'),
                          $t('common.less'),
                          5,
                          model.area_prefix_4
                        ),
                      (value) =>
                        !model.cellular_no_1 ||
                        /^[0-9]*$/.test(model.cellular_no_1),
                      (value) =>
                        !model.cellular_no_1 ||
                        (model.cellular_no_1 &&
                          model.cellular_no_1.length >= 7 &&
                          model.cellular_no_1.length <= 17),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.countryPrefix0'),
                          $t('common.less'),
                          5,
                          model.country_prefix_4
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.dialingArea0'),
                          $t('common.less'),
                          5,
                          model.area_prefix_5
                        ),
                      (value) =>
                        !model.cellular_no_2 ||
                        /^[0-9]*$/.test(model.cellular_no_2),
                      (value) =>
                        !model.cellular_no_2 ||
                        (model.cellular_no_2 &&
                          model.cellular_no_2.length >= 7 &&
                          model.cellular_no_2.length <= 17),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.countryPrefix0'),
                          $t('common.less'),
                          5,
                          model.country_prefix_5
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.countryPrefix0'),
                          $t('common.less'),
                          5,
                          model.country_prefix_6
                        ),
                      (value) =>
                        lessThanValidation(
                          $t('contact.field.dialingArea0'),
                          $t('common.less'),
                          5,
                          model.area_prefix_6
                        ),
                      (value) =>
                        !model.fax ||
                        /^[0-9]*$/.test(model.fax),
                      (value) =>
                        !model.fax ||
                        (model.fax &&
                          model.fax.length >= 7 &&
                          model.fax.length <= 17),
                    ]"
                  >
                    <h6 class="primary--text font-weight-regular">
                      {{ $t("contact.displayText") }}
                    </h6>
                  </v-stepper-step>

                  <v-stepper-content
                    step="3"
                    class="ml-5 mb-auto pb-0 mr-3 py-2 px-2"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-simple-table dense height="424px" fixed-header>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th style="width: 120px">
                                  {{ $t("contact.communication") }}
                                </th>
                                <th>
                                  {{ $t("contact.extension") }}
                                </th>
                                <th>
                                  {{ $t("contact.no") }}
                                </th>
                                <th>
                                  {{ $t("contact.areaCode") }}
                                </th>
                                <th>
                                  {{ $t("contact.country") }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{ $t("contact.tel") }}</td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.extension_1"
                                    :rules="[
                                      lessThanValidation(
                                        $t('contact.field.extension0'),
                                        $t('common.less'),
                                        6,
                                        model.extension_1
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.phone_1"
                                    :rules="phoneValid"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.area_prefix_1"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.dialingArea0'),
                                        $t('common.less'),
                                        5,
                                        model.area_prefix_1
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.country_prefix_1"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.countryPrefix0'),
                                        $t('common.less'),
                                        5,
                                        model.country_prefix_1
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("contact.tel") }}</td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.extension_2"
                                    :rules="[
                                      lessThanValidation(
                                        $t('contact.field.extension0'),
                                        $t('common.less'),
                                        6,
                                        model.extension_2
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.phone_2"
                                    :rules="phoneValid"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.area_prefix_2"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.dialingArea0'),
                                        $t('common.less'),
                                        5,
                                        model.area_prefix_2
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.country_prefix_2"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.countryPrefix0'),
                                        $t('common.less'),
                                        5,
                                        model.country_prefix_2
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("contact.tel") }}</td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.extension_3"
                                    :rules="[
                                      lessThanValidation(
                                        $t('contact.field.extension0'),
                                        $t('common.less'),
                                        6,
                                        model.extension_3
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.phone_3"
                                    :rules="phoneValid"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.area_prefix_3"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.dialingArea0'),
                                        $t('common.less'),
                                        5,
                                        model.area_prefix_3
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.country_prefix_3"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.countryPrefix0'),
                                        $t('common.less'),
                                        5,
                                        model.country_prefix_3
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("contact.cel") }}</td>
                                <td rowspan="3" class="text-top">
                                  <v-checkbox
                                    hide-details="auto"
                                    v-model="model.sms_1"
                                    :label="$t('contact.field.sms')"
                                  ></v-checkbox>
                                  <v-checkbox
                                    hide-details="auto"
                                    v-model="model.sms_2"
                                    :label="$t('contact.field.sms')"
                                  ></v-checkbox>
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.cellular_no_1"
                                    :rules="phoneValid"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.area_prefix_4"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.dialingArea0'),
                                        $t('common.less'),
                                        5,
                                        model.area_prefix_4
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.country_prefix_4"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.countryPrefix0'),
                                        $t('common.less'),
                                        5,
                                        model.country_prefix_4
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("contact.cel") }}</td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.cellular_no_2"
                                    :rules="phoneValid"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.area_prefix_5"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.dialingArea0'),
                                        $t('common.less'),
                                        5,
                                        model.area_prefix_5
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.country_prefix_5"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.countryPrefix0'),
                                        $t('common.less'),
                                        5,
                                        model.country_prefix_5
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("contact.fax") }}</td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.fax"
                                    :rules="faxValid"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.area_prefix_6"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.dialingArea0'),
                                        $t('common.less'),
                                        5,
                                        model.area_prefix_6
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                                <td>
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.country_prefix_6"
                                    :rules="[
                                      lessThanValidation(
                                        $t('supplier.field.countryPrefix0'),
                                        $t('common.less'),
                                        5,
                                        model.country_prefix_6
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("contact.field.email") }}</td>
                                <td colspan="4">
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.email"
                                    :rules="emailValid"
                                    required
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("contact.field.site") }}</td>
                                <td colspan="4">
                                  <v-text-field
                                    hide-details="auto"
                                    v-model="model.site"
                                    :rules="[
                                      lessThanValidation(
                                        $t('contact.field.site'),
                                        $t('common.less'),
                                        100,
                                        model.site
                                      ),
                                    ]"
                                    required
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 my-2">
                      <v-col cols="12" class="text-center pa-0">
                        <v-btn
                          class="ma-2"
                          outlined
                          fab
                          x-small
                          color="primary"
                          @click="e6 = 2"
                        >
                          <v-icon>mdi-chevron-double-up</v-icon>
                        </v-btn>
                        <v-btn
                          disabled
                          class="ma-2"
                          outlined
                          fab
                          x-small
                          color="primary"
                        >
                          <v-icon>mdi-chevron-double-down</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FormSkeleton from "@/components/skeleton/FormSkeleton";
import { changedFormData } from "@/utils";
import Alert from "@/components/Alert";
export default {
  name: "ContactDialog",
  props: ["dialog", "contact_id"],
  components: {
    FormSkeleton,
    Alert,
  },
  data() {
    return {
      message: "",
      alert: false,
      model: {},
      originModel: {},
      previousStep: 1,
      e6: 1,
      loading: false,
      valid: false,
    };
  },
  computed: {
    contactValid() {
      return [
        (v) => !!v || this.$t("formRules.contactRequired"),
        (v) => !/^\s*$/.test(v) || this.$t("formRules.contactRequired"),
        (v) =>
          !v ||
          (v && v.length <= 50) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("contact.contactName"),
            type: this.$t("common.less"),
            size: 50,
          }),
      ];
    },
    emailValid() {
      return [
        (v) =>
          !v || /.+@.+\..+/.test(v) || this.$t("formRules.emailValidMessage"),
        (v) =>
          !v ||
          (v && v.length <= 64) ||
          this.$t("formRules.lengthValidMessage", {
            fieldName: this.$t("contact.field.email"),
            type: this.$t("common.less"),
            size: 64,
          }),
      ];
    },
    phoneValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.phoneNoInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.phoneNoValidMessage"),
      ];
    },
    faxValid() {
      return [
        (v) => !v || /^[0-9]*$/.test(v) || this.$t("formRules.faxInteger"),
        (v) =>
          !v ||
          (v && v.length >= 7 && v.length <= 17) ||
          this.$t("formRules.faxValidMessage"),
      ];
    },
    contactId() {
      return this.contact_id;
    },
    ...mapGetters({
      contactById: "contact/contactById",
      accessRight: "accessRight"
    }),
  },
  watch: {
    e6() {
      if (this.valid) {
        this.alert = false;
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
    contactById() {
      if (Object.keys(this.contactById).length > 0) {
        this.model = Object.assign({}, this.model, this.contactById);
        this.originModel = Object.assign({}, this.originModel, this.model);
      }
    },
    async contact_id(val) {
      if (val) {
        await this.$store.dispatch("contact/GetContactById", this.contact_id);
      }
    },
    dialog() {
      this.alert = false;
      if (!this.dialog) {
        this.$refs.form.reset();
        this.model = {};
        this.e6 = 1;
        this.previousStep = 1;
        this.loading = false;
        this.$store.commit("contact/SET_CONTACT_BY_ID", {});
        this.$emit("update:contact_id", "");
      }
    },
  },
  methods: {
    accordionStepper(current) {
      if (this.previousStep == current && this.previousStep != 4) {
        this.e6 = 4;
        this.previousStep = 4;
      } else {
        this.previousStep = current;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && v.length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    async saveContact() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.model.record_type_number = Number(this.$route.params.supplier_id);
        this.model.record_type = "suppliers";
        let data;
        if (this.contactId) {
          // save only update model value using changedFormData from utils
          let saveData = changedFormData(this.model, this.originModel);
          data = await this.$store
            .dispatch("contact/EditContact", {
              contact_id: this.contactId,
              contact: saveData,
            })
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
        } else {
          data = await this.$store
            .dispatch("contact/SetContact", this.model)
            .catch((e) => {
              this.loading = false;
              console.log(e);
            });
        }
        if (data) {
          await this.$store.dispatch(
            "supplier/GetSupplierById",
            this.$route.params.supplier_id
          );
          this.$emit("update:dialog", false);
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
  },
};
</script>