<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('supplier.supplierActions') }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="(supplierId && !!model.is_locked && model.user_id!==user.id)">
      <v-col cols="12" class="my-3">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1 error--text" >*{{ model.user ? $t('common.documentReadOnlyModeNotice',{userId:model.user.name}) : '' }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto pb-3"
          outlined
        >
          <v-card-title class="pa-0">
            <v-stepper class="pb-0" width="100%" v-model="headerStep" non-linear flat>
              <v-stepper-header class="elevation-0">
                <v-stepper-step
                  class="py-1 px-4 text-body-1"
                  :complete="headerStep > 1"
                  editable
                  step="1"
                >
                  <h5 style="max-width: 150px;" :class="supplierId ? 'text-truncate px-1 font-italic primary--text font-weight-bold' : 'primary--text text--darken-1'" class="font-weight-regular">{{ supplierId ? supplierName && `${supplierId}. ${supplierName}`  : $t('supplier.addSupplier') }}</h5>
                </v-stepper-step>

                <v-divider />

                <v-stepper-step
                  class="py-1 px-4 text-body-1"
                  :complete="headerStep == 1 && this.$route.params.supplier_id && (!!model.is_locked && model.user_id==user.id || !model.user_id) ? true : false"
                  :editable="this.$route.params.supplier_id && (!!model.is_locked && model.user_id==user.id || !model.user_id) ? true : false"
                  step="2"
                >
                  <h5 class="primary--text font-weight-regular">{{$t('contact.contact')}}</h5>
                </v-stepper-step>
              </v-stepper-header>
              <v-divider />
              <v-stepper-items>
                <v-stepper-content step="1" class="pa-0">
                  <SupplierStep :supplierName.sync="supplierName" :loading.sync="loading" :loadingSaveAndContinue.sync="loadingSaveAndContinue" @saveRole="saveRole" :inputData.sync="model" />
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-2">
                  <ContactStep v-if="this.$route.params.supplier_id" :supplierId="supplierId"/>
                </v-stepper-content>
              </v-stepper-items>  
            </v-stepper>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import SupplierStep from './Steps/SupplierStep.vue';
import ContactStep from './Steps/ContactStep.vue';
export default {
  name: 'SupplierAction',
  components: {
    SupplierStep,
    ContactStep
  },
  data() {
    return {
      model: {},
      headerStep: 1,
      supplierName: '',
      loading: false,
      loadingSaveAndContinue: false
    }
  },
  computed: {
    supplierId() {
      return this.$route.params.supplier_id
    },
    ...mapGetters({
      supplierQuery: 'supplier/supplierQuery',
      accessRight: 'accessRight',
      user: 'user',
      moreThanOneUserLogout: 'moreThanOneUserLogout'
    }),
  },
  watch: {
    async moreThanOneUserLogout() {
      if (this.moreThanOneUserLogout && this.model.id) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Supplier',id:this.model.id,is_locked:false});
        this.$store.dispatch('LogOut');
        this.$router.push({ name: 'login' });
      }
    }
  },
  async created() {
    window.addEventListener('beforeunload', this.handler)
    if (this.$route.params.supplier_id) {
      await this.$store.dispatch('supplier/GetSupplierById', this.$route.params.supplier_id);
      if (!this.model.is_locked && this.$route.params.supplier_id) {
       await this.$store.dispatch('PostDocumentOpenAction',{table:'Supplier',id:this.$route.params.supplier_id,is_locked:true});
      }
    }
  },
  beforeDestroy() {
    if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
      this.$store.dispatch('PostDocumentOpenAction',{table:'Supplier',id:this.model.id,is_locked:false});
    }
    this.$refs.form ? this.$refs.form.reset() : '';
    this.$store.commit('SET_USER_LOGOUT', false)
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    async handler(event) {
      if (this.model.id && !this.moreThanOneUserLogout && this.model.is_locked && this.model.user_id == this.user.id || this.model.id && !this.model.user_id && !this.moreThanOneUserLogout) {
        await this.$store.dispatch('PostDocumentOpenAction',{table:'Supplier',id:this.model.id,is_locked:false});
      }
      event.preventDefault();
    },
    callBackFunction() {
      this.$router.push({
        name: "Supplier",
        query: {
          ...this.supplierQuery,
        },
      });
    },
    async saveRole(step = false) {
      if(!step) {
        this.loading = true;
      } else {
        this.loadingSaveAndContinue = true;
      }
      let data;
      if(this.$route.params.supplier_id) {
        data = await this.$store.dispatch('supplier/EditSupplier', {supplier_id: this.$route.params.supplier_id, supplier: this.model}).catch((e) => {
          this.loading = false;
          this.loadingSaveAndContinue = false;
          console.log(e)
        });  
        if(data && !step) {
          this.loading = false;
          this.$store.dispatch('supplier/GetSupplierById', data.data.id);
        } else {
          this.loadingSaveAndContinue = false;
          this.headerStep = 2;
        }
      } else {
        if (this.$route.params.copiedModel) {
          let copyModel = this.model;
          copyModel = Object.entries(copyModel).reduce((acc, [key, val]) => {
            if (!val || typeof val === 'object') return acc;
            return { ...acc, [key]: val };
          }, {})
          delete copyModel.id;
          delete copyModel.created_at;
          delete copyModel.updated_at;
          data = await this.$store.dispatch('supplier/SetSupplier', copyModel).catch((e) => {
            this.loading = false;
            this.loadingSaveAndContinue = false;
            console.log(e)
          });
        } else {
          data = await this.$store.dispatch('supplier/SetSupplier', this.model).catch((e) => {
            this.loading = false;
            this.loadingSaveAndContinue = false;
            console.log(e)
          });
        }
        if(data) {
          this.headerStep = 2;
          this.loading = false;
          this.loadingSaveAndContinue = false;
          if (this.accessRight.includes('edit')) {
            this.$store.dispatch('supplier/GetSupplierById', data.data.id);
            this.$router.push('/supplier/editSupplier/' + data.data.id);
          } else {
            this.callBackFunction()
          }
        }
      }
    }
  },
}
</script>
